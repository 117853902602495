import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckOutForm";
import './checkout.css';

const stripePromise = loadStripe("your-publishable-key-here");

function FinalCheckOutElement() {
  return (
    <Elements stripe={stripePromise}>
      <div className="container-style">
        <CheckoutForm />
      </div>
    </Elements>
  );
}



export default FinalCheckOutElement;
