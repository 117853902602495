import React, { useRef, useEffect, useState } from 'react';

const ConversationList = ({ onUserSelect }) => {
  const messagesData = {
    users: ['Alice', 'Bob', 'Charlie', 'David'],
    conversations: [
      {
        user: 'Alice',
        latestMessage: {
          message: "Yes, I'll be there. What time?",
          timestamp: "2024-05-12T10:05:00Z"
        }
      },
      {
        user: 'Bob',
        latestMessage: {
          message: "Yes, I'll review it and get back to you by tomorrow.",
          timestamp: "2024-05-12T09:35:00Z"
        }
      },
      {
        user: 'Charlie',
        latestMessage: null // No messages yet
      },
      {
        user: 'David',
        latestMessage: null // No messages yet
      }
    ]
  };

  const [maxMessageLength, setMaxMessageLength] = useState(20);
  const messageContainerRef = useRef(null);

  useEffect(() => {
    if (messageContainerRef.current) {
      const containerWidth = messageContainerRef.current.offsetWidth;
      setMaxMessageLength(containerWidth / 10);
    }
  }, []);

  return (
    <div className="conversation-list" ref={messageContainerRef}>
      <h2>Conversations</h2>
      <ul>
        {messagesData.conversations.map((conversation) => (
          <li key={conversation.user} onClick={() => onUserSelect(conversation.user)}>
            <div className="conversation-preview">
              <h3>{conversation.user}</h3>
              {conversation.latestMessage ? (
                <p>{conversation.latestMessage.message.slice(0, maxMessageLength)}...</p>
              ) : (
                <p>No messages yet</p>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ConversationList;
