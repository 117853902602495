import React from "react";
import { useState } from "react";
import "./style.css";
import logo from "../../assets/Logo.png";
import { useNavigate } from "react-router-dom";

const BetaLaunch = () => {
  const [formData, setFormData] = useState({ name: "", email: "" });
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    try {
      const resp = await fetch("https://skenderpay.xyz:8443/betaSignUp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!resp.ok) {
        const errorText = await resp.text();
        throw new Error(
          `HTTP error! Status: ${resp.status}, Message: ${errorText}`
        );
      }

      if (resp.ok) {
        setSuccess(true);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while submitting the form. Please try again.");
    }
  };

  const toggleLogin = () => {
    navigate("/login");
  };

  const toggleSignUp = () => {
    navigate("/login");
  };

  return (
    <div className="container-main ">
      <header>
        <div className="container">
          <div className="header-div">
            <img src={logo} className="logo img1" alt="Logo" />
            <div>
              <p>SkenderPay</p>
            </div>
          </div>
          <div>
            <p>AI-powered digital payments for the modern world.</p>
          </div>
          {/* <div className="header-button-container">
            <button className="header-button" onClick={toggleLogin}>
              Login
            </button>
            <button className="header-button" onClick={toggleSignUp}>
              SignUp
            </button>
          </div> */}
        </div>
      </header>

      {!success && (
        <section className="waitlist-section">
          <h2 className="h2-font-1">
            Welcome to SkenderPay - Where Payments & Commerce Meet Innovation!
          </h2>
          <p className="p-1-para">
            Looking to grow your business, sell your services, or earn extra
            income? SkenderPay is here to help you unlock new revenue streams by
            accepting payments in both crypto and fiat currencies, all within
            one powerful platform. Built for entrepreneurs, creators, and small
            businesses, our marketplace is designed to make selling easier, more
            secure, and rewarding.
          </p>
          <h2 className="h2-font-1">
            Be the first to experience the future of payments
          </h2>
          <p className="p-1-para">
            Be one of the first to use SkenderPay, and start creating additional
            revenue for your products or services. Don't miss out on exclusive
            features and rewards only available to our early users.
          </p>
          <h2 className="h2-font-1">
            Join our beta waitlist for exclusive early access.
          </h2>
          <form id="waitlist-form" onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Full Name"
              className="inputHolder"
              value={formData.fullName}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email Address"
              className="inputHolder"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <div>
              <button type="submit" className="submit-button">
                Join the Beta
              </button>
            </div>
          </form>
        </section>
      )}

      {success && (
        <section className="waitlist-section">
          <h2 className="h2-font-1">Thank you for signing up!</h2>
        </section>
      )}

      <footer>
        <p>&copy; 2024 SkenderPay. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default BetaLaunch;
