import logo from "../../assets/Logo.png";
import middleImg from "../../assets/MiddleImg.png";

// const Signup = (props) => {
//   const validateEmail = (input) => {
//     var validRegex =
//       /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
//     if (input.match(validRegex)) {
//       return true;
//     } else {
//       return false;
//     }
//   };

//   const validateName = (input) => {
//     var validRegex = /^[a-zA-Z]/;
//     if (input.match(validRegex)) {
//       return true;
//     } else {
//       return false;
//     }
//   };

//   const validatePassword = (input) => {
//     let out = false;
//     var lowerCaseLetters = /[a-z]/g;
//     if (input.match(lowerCaseLetters)) {
//       out = true;
//     } else {
//       out = false;
//     }

//     // Validate capital letters
//     var upperCaseLetters = /[A-Z]/g;
//     if (input.match(upperCaseLetters)) {
//       out = true;
//     } else {
//       out = false;
//     }

//     // Validate numbers
//     var numbers = /[0-9]/g;
//     if (input.match(numbers)) {
//       out = true;
//     } else {
//       out = false;
//     }

//     // Validate length
//     if (input.length >= 8) {
//       out = true;
//     } else {
//       out = false;
//     }
//     return out;
//   };

//   const {
//     value: enteredFName,
//     isValid: enteredFNameIsValid,
//     hasError: fNameInputHasError,
//     valueChangeHandler: fNameInputChangeHandler,
//     blurHandler: fNameInputBlurHandler,
//     reset: fNameInputReset,
//   } = useInputVal(validateName);

//   const {
//     value: enteredLName,
//     isValid: enteredLNameIsValid,
//     hasError: lNameInputHasError,
//     valueChangeHandler: lNameInputChangeHandler,
//     blurHandler: lNameInputBlurHandler,
//     reset: lNameInputReset,
//   } = useInputVal(validateName);

//   const {
//     value: enteredEmail,
//     isValid: enteredEmailIsValid,
//     hasError: emailInputHasError,
//     valueChangeHandler: emailInputChangeHandler,
//     blurHandler: emailInputBlurHandler,
//     reset: emailInputReset,
//   } = useInputVal(validateEmail);

//   const {
//     value: enteredPassword,
//     isValid: enteredPasswordIsValid,
//     hasError: passwordHasError,
//     valueChangeHandler: passwordChangeHandler,
//     blurHandler: passwordBlurHandler,
//     reset: passwordInputReset,
//   } = useInputVal(validatePassword);

//   const {
//     value: enteredConfPassword,
//     isValid: enteredConfPasswordIsValid,
//     hasError: confPasswordHasError,
//     valueChangeHandler: confPasswordChangeHandler,
//     blurHandler: confPasswordBlurHandler,
//     reset: confPasswordInputReset,
//   } = useInputVal(validatePassword);

//   let formIsValid = false;

//   let passwordnotMatch = true;
//   if (enteredPassword === enteredConfPassword) {
//     passwordnotMatch = false;
//   }

//   if (
//     enteredEmailIsValid &&
//     enteredPasswordIsValid &&
//     enteredConfPasswordIsValid &&
//     !passwordnotMatch &&
//     enteredFNameIsValid &&
//     enteredLNameIsValid
//   ) {
//     formIsValid = true;
//   }

//   const navigate = useNavigate();

//   if (formIsValid) {

//   }
//   const onSubmitHandler = async (event) => {
//     event.preventDefault();
//     if (!formIsValid) {
//       return;
//     }
//     const data = {
//       firstName: enteredFName,
//       lastName: enteredLName,
//       email: enteredEmail,
//       password: enteredPassword,
//     };
//     const ret = await pushToDB(data);
//     console.log(ret);
//     if(ret === "ok"){
//       emailInputReset();
//       passwordInputReset();
//       confPasswordInputReset();
//       fNameInputReset();
//       lNameInputReset();
//       navigate("/profile");
//     }else{
//       console.log(ret)
//     }
//   };

//   const pushToDB = async (data) => {
//     try {
//       const resp = await fetch("https://skenderpay.xyz:8443/", {
//         method: "POST",
//         headers: {
//           Accept: "application/json",
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(data),
//       });
//       const parsedResp = await resp.json();
//       console.log(parsedResp);
//       if (parsedResp.message === 'success'){
//         return "ok";
//       }else{
//         return resp;
//       }
//     } catch (e) {
//       return e;
//     }
//   };

//   const emailClasses = emailInputHasError
//     ? "input invalid"
//     : "input";
//   const passwordClasses = passwordHasError
//     ? "input invalid"
//     : "input";
//   const confPasswordClasses = confPasswordHasError
//     ? "input invalid"
//     : "input";
//   const fnameClasses = fNameInputHasError
//     ? "input invalid"
//     : "input";
//   const lnameClasses = lNameInputHasError
//     ? "input invalid"
//     : "input";

//   return (
//     <div className="frame3-signup-empty-container">
//       <div className="frame3-signup-empty-frame3-signup-empty">
//       <div className="signup-page">
//         <div className="signup-form">
//           <div className="signup-element">
//             <span className="bodylargeregular">
//               <span>
//                 Safeguarding Your Digital Wealth with an Extra Layer of
//                 Protection
//               </span>
//             </span>
//           </div>
//           <div className="signup-element">
//             <label htmlFor="firstName" className="label">
//               First Name
//             </label>
//             <input
//               id="firstName"
//               type="text"
//               className={fnameClasses}
//               onChange={fNameInputChangeHandler}
//               placeholder="Enter your first name"
//               onBlur={fNameInputBlurHandler}
//               value={enteredFName}
//               required
//             />
//           </div>
//           <div className="signup-element">
//             <label htmlFor="lastName" className="label">
//               Last Name
//             </label>
//             <input
//               id="lastName"
//               type="text"
//               className={lnameClasses}
//               onChange={lNameInputChangeHandler}
//               placeholder="Enter your last name"
//               onBlur={lNameInputBlurHandler}
//               value={enteredLName}
//             />
//           </div>
//           <div className="signup-element">
//             <label htmlFor="email" className="label">
//               Email
//             </label>
//             <input
//               id="email"
//               type="text"
//               className={emailClasses}
//               onChange={emailInputChangeHandler}
//               placeholder="Enter your email address"
//               onBlur={emailInputBlurHandler}
//               value={enteredEmail}
//             />
//           </div>
//           <div className="signup-element">
//             <label htmlFor="password" className="label">
//               Password
//             </label>
//             <input
//               type="password"
//               id="password"
//               className={passwordClasses}
//               onChange={passwordChangeHandler}
//               placeholder="Enter a password"
//               onBlur={passwordBlurHandler}
//               value={enteredPassword}
//             />
//           </div>
//           <div className="signup-element">
//             <label htmlFor="confPassword" className="label">
//               Confirm Password
//             </label>
//             <input
//               id="confPassword"
//               type="password"
//               className={confPasswordClasses}
//               onChange={confPasswordChangeHandler}
//               placeholder="Retype your password"
//               onBlur={confPasswordBlurHandler}
//               value={enteredConfPassword}
//             />
//           </div>
//           <div className="signup-element">
//             <button
//               disabled={!formIsValid}
//               onClick={onSubmitHandler}
//               className="signup-button"
//             >
//               <span className="frame3-signup-empty-text05 bodylargeextrabold">
//                 <span>Continue</span>
//               </span>
//             </button>
//           </div>
//           <div className="frame3-signup-empty-text07">
//             <span className="frame3-signup-empty-text08 bodylargesemibold">
//               <span>Privacy Policy</span>
//             </span>
//             <span className="frame3-signup-empty-text10 bodylargesemibold">
//               <span>Copyright 2023</span>
//             </span>
//           </div>
//         </div>
//       </div>
//         <div className="frame4-signup-filled-illustration">
//           <div className="frame4-signup-filled-pattern">
//             <div className="frame4-signup-filled-group1">
//               <img src={grouptr} alt="Vector Top Right" />
//             </div>
//             <img
//               src={vectorbl}
//               alt="Vector Bottom Left"
//               className="frame4-signup-filled-vector36"
//             />
//           </div>
//           <div className="frame4-signup-filled-logo">
//             <div className="frame4-signup-filled-frame48">
//               <div className="frame4-signup-filled-logo1">
//                 <div className="frame4-signup-filled-logo2">
//                   <div className="frame4-signup-filled-ontologyont">
//                     <img
//                       src={logo}
//                       alt="SkenderPay1I114"
//                       className="frame4-signup-filled-skender-pay1"
//                     />
//                   </div>
//                 </div>
//               </div>
//               <span className="frame4-signup-filled-text16 HeadingH5">
//                 <span>Skenderpay</span>
//               </span>
//             </div>
//           </div>
//           <div className="frame4-signup-filled-title">
//             <div className="frame4-signup-filled-text18">
//               <span className="frame4-signup-filled-text19 HeadingH2">
//                 <span>Speady, Easy and Fast</span>
//               </span>
//               <span className="frame4-signup-filled-text21">
//                 <span className="frame4-signup-filled-text22">
//                   Overpay help you set saving goals, earn cash back offers, Go
//                   to disclaimer for more details and get paychecks up to two
//                   days early. Get a
//                   <span
//                     dangerouslySetInnerHTML={{
//                       __html: " ",
//                     }}
//                   />
//                 </span>
//                 <span className="frame3-signup-filled-text23">$20</span>
//                 <span> bonus when you receive qualifying direct deposits</span>
//               </span>
//             </div>
//           </div>
//           <div className="frame4-signup-filled-group254">
//             <div className="frame4-signup-filled-group253">
//               <img src={sideImg1} alt="sideImage1" />
//             </div>
//             <img
//               src={sideframe1}
//               alt="Frame51111143"
//               className="frame4-signup-filled-frame5111"
//             />
//             <img
//               src={sideframe2}
//               alt="Frame5511143"
//               className="frame4-signup-filled-frame551"
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Signup;

// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import styles from "./SignupPage.module.css";

// const Input = ({ label, type, placeholder, value, onChange }) => (
//   <div className={styles.inputGroup}>
//     <label className={styles.label}>{label}</label>
//     <input
//       type={type}
//       placeholder={placeholder}
//       value={value}
//       onChange={onChange}
//       className={styles.input}
//     />
//   </div>
// );

// const LeftPanel = () => (
//   <div className={styles.leftPanel}>
//     <div className={styles.logo}>
//       <img src={logo} alt="Logo" />
//       <span className={styles.logoText}>Skenderpay</span>
//     </div>

//     <div className={styles.dashboardPreview}>
//       <img src={middleImg} alt="Middle Image"/>
//     </div>

//     <div className={styles.content}>
//       <h1 className={styles.title}>Speady, Easy and Fast</h1>
//       <p className={styles.description}>
//         Skenderpay help you set saving goals, earn cash back offers, Go to
//         disclaimer for more details and get paychecks up to two days early.
//       </p>
//     </div>

//     <div className={styles.decorativeDots}></div>
//   </div>
// );

// export default function SignupPage() {
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     firstName: "",
//     lastName: "",
//     email: "",
//     password: "",
//     confirmPassword: "",
//   });

//   const handleChange = (field) => (e) => {
//     setFormData((prev) => ({
//       ...prev,
//       [field]: e.target.value,
//     }));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     navigate("/dashboard");
//   };

//   return (
//     <div className={styles.page}>
//       <LeftPanel />

//       <div className={styles.rightPanel}>
//         <form onSubmit={handleSubmit} className={styles.form}>
//           <h2 className={styles.formTitle}>
//             Safeguarding Your Digital Wealth with an Extra Layer of Protection
//           </h2>

//           <div className={styles.formFields}>
//             <Input
//               label="First Name"
//               type="text"
//               placeholder="Enter your first name"
//               value={formData.firstName}
//               onChange={handleChange("firstName")}
//             />
//             <Input
//               label="Last Name"
//               type="text"
//               placeholder="Enter your last name"
//               value={formData.lastName}
//               onChange={handleChange("lastName")}
//             />
//             <Input
//               label="Email"
//               type="email"
//               placeholder="Enter your email address"
//               value={formData.email}
//               onChange={handleChange("email")}
//             />
//             <Input
//               label="Password"
//               type="password"
//               placeholder="Enter a password"
//               value={formData.password}
//               onChange={handleChange("password")}
//             />
//             <Input
//               label="Confirm Password"
//               type="password"
//               placeholder="Retype your password"
//               value={formData.confirmPassword}
//               onChange={handleChange("confirmPassword")}
//             />
//           </div>

//           <button type="submit" className={styles.submitButton}>
//             Continue
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// }
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./SignupPage.module.css";
// import logo from "../../assets/Logo.png";
// import middleImg from "../../assets/MiddleImg.png";
import { Eye, EyeOff } from "lucide-react";

const Input = ({
  label,
  type,
  placeholder,
  value,
  onChange,
  onBlur,
  error,
}) => (
  <div className={styles.inputGroup}>
    <label className={styles.label}>{label}</label>
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      className={`${styles.input} ${error ? styles.inputError : ""}`}
    />
    {error && <span className={styles.errorText}>{error}</span>}
  </div>
);

const PasswordInput = ({
  label,
  placeholder,
  value,
  onChange,
  onBlur,
  error,
  isPasswordVisible,
  togglePasswordVisibility,
}) => (
  <div className={styles.inputGroup}>
    <label className={styles.label}>{label}</label>
    <div className={styles.passwordContainer}>
      <input
        type={isPasswordVisible ? "text" : "password"}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className={`${styles.input} ${error ? styles.inputError : ""}`}
      />
      <button
        type="button"
        className={styles.passwordToggle}
        onClick={togglePasswordVisibility}
        aria-label={isPasswordVisible ? "Hide password" : "Show password"}
      >
        {isPasswordVisible ? <EyeOff size={20} /> : <Eye size={20} />}
      </button>
    </div>
    {error && <span className={styles.errorText}>{error}</span>}
  </div>
);

const LeftPanel = () => (
  <div className={styles.leftPanel}>
    <div className={styles.logo}>
      <img src={logo} alt="Logo" />
      <span className={styles.logoText}>Skenderpay</span>
    </div>
    <div className={styles.dashboardPreview}>
      <img src={middleImg} alt="Dashboard Preview" />
    </div>
    <div className={styles.content}>
      <h1 className={styles.title}>Speedy, Easy and Fast</h1>
      <p className={styles.description}>
        Skenderpay helps you set saving goals, earn cash back offers, and get
        paychecks up to two days early.
      </p>
    </div>
    <div className={styles.decorativeDots}></div>
  </div>
);

export default function SignupPage() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);

  const validate = () => {
    const newErrors = {};

    // First Name validation
    if (!formData.firstName.trim()) {
      newErrors.firstName = "First name is required.";
    } else if (!/^[A-Za-z]+$/.test(formData.firstName)) {
      newErrors.firstName = "First name should only contain letters.";
    }

    // Last Name validation
    if (!formData.lastName.trim()) {
      newErrors.lastName = "Last name is required.";
    } else if (!/^[A-Za-z]+$/.test(formData.lastName)) {
      newErrors.lastName = "Last name should only contain letters.";
    }

    // Email validation
    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(formData.email)
    ) {
      newErrors.email = "Please enter a valid email address.";
    }

    // Password validation
    if (!formData.password) {
      newErrors.password = "Password is required.";
    } else if (
      formData.password.length < 8 ||
      !/[A-Z]/.test(formData.password) ||
      !/[a-z]/.test(formData.password) ||
      !/[0-9]/.test(formData.password)
    ) {
      newErrors.password =
        "Password must be at least 8 characters, with uppercase, lowercase, and a number.";
    }

    // Confirm Password validation
    if (formData.confirmPassword !== formData.password) {
      newErrors.confirmPassword = "Passwords do not match.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (field) => (e) => {
    setFormData((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      navigate("/dashboard");
    }
  };

  return (
    <div className={styles.page}>
      <LeftPanel />

      <div className={styles.rightPanel}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <h2 className={styles.formTitle}>
            Safeguarding Your Digital Wealth with an Extra Layer of Protection
          </h2>

          <div className={styles.formFields}>
            <Input
              label="First Name"
              type="text"
              placeholder="Enter your first name"
              value={formData.firstName}
              onChange={handleChange("firstName")}
              onBlur={() => validate()}
              error={errors.firstName}
            />
            <Input
              label="Last Name"
              type="text"
              placeholder="Enter your last name"
              value={formData.lastName}
              onChange={handleChange("lastName")}
              onBlur={() => validate()}
              error={errors.lastName}
            />
            <Input
              label="Email"
              type="email"
              placeholder="Enter your email address"
              value={formData.email}
              onChange={handleChange("email")}
              onBlur={() => validate()}
              error={errors.email}
            />
            <PasswordInput
              label="Password"
              placeholder="Enter a password"
              value={formData.password}
              onChange={handleChange("password")}
              onBlur={() => validate()}
              error={errors.password}
              isPasswordVisible={isPasswordVisible}
              togglePasswordVisibility={() =>
                setIsPasswordVisible(!isPasswordVisible)
              }
            />
            <PasswordInput
              label="Confirm Password"
              placeholder="Retype your password"
              value={formData.confirmPassword}
              onChange={handleChange("confirmPassword")}
              onBlur={() => validate()}
              error={errors.confirmPassword}
              isPasswordVisible={isConfirmPasswordVisible}
              togglePasswordVisibility={() =>
                setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
              }
            />
          </div>

          <button type="submit" className={styles.submitButton}>
            Continue
          </button>
        </form>
      </div>
    </div>
  );
}
