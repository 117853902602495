import React, { useState } from "react";
import "./Dashboard.css";
import { Link } from "react-router-dom";
import dropdownside from "../../assets/sideway.png";
import Wallet from "./Wallet";
import Bank from "./Bank";
import Navbar from "./Navbar";
import NavbarTop from "./NavbarTop";
import { useSelector, useDispatch } from "react-redux";
import { loginAction } from "../../store/login-slice";
import { useTable, useSortBy } from "react-table";
import dropdown from "../../assets/dropdown.png";
import message from "../../assets/message.png";
import tax from "../../assets/tax.png";
import saving from "../../assets/saving.png";
import business from "../../assets/businessAccount.png";
import wallet1 from "../../assets/wallet1.png";
import search from "../../assets/search.png";
import bell from "../../assets/bell.png";
import reddot from "../../assets/redDot.png";
import profilephoto from "../../assets/profilephoto.png";
import quickt from "../../assets/quicktransfer.png";
import date from "../../assets/date.png";
import bitcoin from "../../assets/bitcoin.png";
import profile2 from "../../assets/profile.png";
import paypal from "../../assets/paypal.png";
import help from "../../assets/help.png";
import setting from "../../assets/setting.png";
import blueLine from "../../assets/blueLine.png";
import blackLine from "../../assets/blackLine.png";
import blackUnion from "../../assets/blackUnion.png";
import dot from "../../assets/dot.png";
import FinalCheckOutElement from "../SripeCheckout/FinalCheckOutElement";
const Dashboard = (props) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const transactions = [
    {
      productName: "Product 1",
      period: "3 Months",
      type: "Promotion",
      price: "$835.00",
      status: "Success",
    },
    {
      productName: "Product 2",
      period: "6 Months",
      type: "Full Service",
      price: "$835.00",
      status: "Success",
    },
    {
      productName: "Product 3",
      period: "15 Days",
      type: "Promotion",
      price: "$150.00",
      status: "Pending",
    },
    {
      productName: "Product 4",
      period: "Lifetime",
      type: "Subscription",
      price: "$200.00",
      status: "Success",
    },
  ];

  const Table = ({ columns, data }) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable(
        {
          columns,
          data,
        },
        useSortBy
      );

    return (
      <table {...getTableProps()} className="product-list">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="product-item">
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? "sort-desc"
                        : "sort-asc"
                      : ""
                  }
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="product-item">
                {row.cells.map((cell) => {
                  // Add specific classes based on status
                  let statusClass = "";
                  if (cell.column.id === "status") {
                    statusClass =
                      cell.value === "Pending"
                        ? "product-pending"
                        : "product-success";
                  }
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={`product-name ${statusClass}`}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Product Name",
        accessor: "productName",
      },
      {
        Header: "Period",
        accessor: "period",
      },
      {
        Header: "Price",
        accessor: "price",
      },
      {
        Header: "Status",
        accessor: "status",
      },
    ],
    []
  );

  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleNavbarClick = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleLogout = () => {
    dispatch(loginAction.userLogout());
  };
  return (
    <div className="frame9-home-v2-container">
      <div className="frame9-home-v2-frame9-home-v2">
        <div className="frame9-home-v2-content">
          <NavbarTop moduleName="Dashboard" onNavbarClick={toggleSidebar} />
          <div className="frame9-home-v2-tax">
            <img
              src={tax}
              alt="Rectangle8I194"
              className="frame9-home-v2-rectangle8"
            />
            <div className="frame9-home-v2-name">
              {/* <div className="frame9-home-v2-text038">
                <span className="frame9-home-v2-text039 bodysmallmedium">
                  <span>Total Month Earn</span>
                </span>
                <span className="frame9-home-v2-text041 HeadingH5">
                  <span>$2,456.89</span>
                </span>
              </div> */}
              {/* <div className="frame9-home-v2-icon">
                <div className="frame9-home-v2-receipt">
                  <div className="frame9-home-v2-receipt1">
                    <div className="frame9-home-v2-receipt2">
                      <img
                        src="/vectori194-yrm.svg"
                        alt="VectorI194"
                        className="frame9-home-v2-vector02"
                      />
                      <img
                        src="/vectori194-xk1.svg"
                        alt="VectorI194"
                        className="frame9-home-v2-vector03"
                      />
                      <img
                        src="/vectori194-ty8i.svg"
                        alt="VectorI194"
                        className="frame9-home-v2-vector04"
                      />
                      <img
                        src
                        alt="VectorI194"
                        className="frame9-home-v2-vector05"
                      />
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            {/* <div className="frame9-home-v2-stats">
              <img src alt="barI194" className="frame9-home-v2-bar" />
              <img src alt="barI194" className="frame9-home-v2-bar01" />
              <img src alt="barI194" className="frame9-home-v2-bar02" />
              <img src alt="barI194" className="frame9-home-v2-bar03" />
              <img src alt="barI194" className="frame9-home-v2-bar04" />
            </div> */}
          </div>
          <div className="frame9-home-v2-saving">
            <img
              src={saving}
              alt="Rectangle8I194"
              className="frame9-home-v2-rectangle81"
            />
            {/* <div className="frame9-home-v2-name1">
              <div className="frame9-home-v2-text043">
                <span className="frame9-home-v2-text044 bodysmallmedium">
                  <span>Total claims</span>
                </span>
                <span className="frame9-home-v2-text046 HeadingH5">
                  <span>$280.00</span>
                </span>
              </div>
              <div className="frame9-home-v2-icon1">
                <div className="frame9-home-v2-briefcase">
                  <div className="frame9-home-v2-group01">
                    <img
                      src="/vectori194-fyp.svg"
                      alt="VectorI194"
                      className="frame9-home-v2-vector06"
                    />
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="frame9-home-v2-stats1">
              <img src alt="barI194" className="frame9-home-v2-bar05" />
              <img src alt="barI194" className="frame9-home-v2-bar06" />
              <img src alt="barI194" className="frame9-home-v2-bar07" />
              <img src alt="barI194" className="frame9-home-v2-bar08" />
              <img src alt="barI194" className="frame9-home-v2-bar09" />
            </div> */}
          </div>
          <div className="frame9-home-v2-wallet1">
            <img
              src={wallet1}
              alt="backgroundI194"
              className="frame9-home-v2-background"
            />
            {/* <div className="frame9-home-v2-frame58">
              <div className="frame9-home-v2-frame57">
                <span className="frame9-home-v2-text048 bodylargesemibold">
                  <span>Main account</span>
                </span>
                <div className="frame9-home-v2-frame56">
                  <span className="frame9-home-v2-text050 bodylargemedium">
                    <span>0x1A1zP1....vfNa</span>
                  </span>
                  <div className="frame9-home-v2-copy">
                    <div className="frame9-home-v2-group02">
                      <img
                        src="/vectori194-y07.svg"
                        alt="VectorI194"
                        className="frame9-home-v2-vector07"
                      />
                      <img
                        src="/vectori194-artl.svg"
                        alt="VectorI194"
                        className="frame9-home-v2-vector08"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame9-home-v2-balance">
                <div className="frame9-home-v2-title">
                  <span className="frame9-home-v2-text052 bodylargemedium">
                    <span>Total Balance</span>
                  </span>
                </div>
                <div className="frame9-home-v2-frame59">
                  <span className="frame9-home-v2-text054">
                    <span className="frame9-home-v2-text055">
                      56,476.00
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>SKD</span>
                  </span>
                  <div className="frame9-home-v2-up">
                    <span className="frame9-home-v2-text057 bodylargemedium">
                      <span>2,938,45 USD</span>
                    </span>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <img
              src="/line6i194-avkq.svg"
              alt="Line6I194"
              className="frame9-home-v2-line6"
            />
            <span className="frame9-home-v2-text059 HeadingH6">
              <span>Wallet</span>
            </span>
            <div className="frame9-home-v2-dotsvertical">
              <div className="frame9-home-v2-group03">
                <img
                  src="/vectori194-0btn.svg"
                  alt="VectorI194"
                  className="frame9-home-v2-vector09"
                />
                <img
                  src="/vectori194-hfa5h.svg"
                  alt="VectorI194"
                  className="frame9-home-v2-vector10"
                />
                <img
                  src="/vectori194-0htu.svg"
                  alt="VectorI194"
                  className="frame9-home-v2-vector11"
                />
              </div>
            </div> */}
            <div className="frame9-home-v2-menu">
              {/* <div className="frame9-home-v2-item">
                <div className="frame9-home-v2-icon2">
                  <div className="frame9-home-v2-elementplus">
                    <div className="frame9-home-v2-elementplus1">
                      <div className="frame9-home-v2-elementplus2">
                        <img
                          src="/vectori194-whuc.svg"
                          alt="VectorI194"
                          className="frame9-home-v2-vector12"
                        />
                        <img
                          src="/vectori194-mgqr.svg"
                          alt="VectorI194"
                          className="frame9-home-v2-vector13"
                        />
                        <img
                          src="/vectori194-gd3.svg"
                          alt="VectorI194"
                          className="frame9-home-v2-vector14"
                        />
                        <img
                          src="/vectori194-mwee.svg"
                          alt="VectorI194"
                          className="frame9-home-v2-vector15"
                        />
                        <img
                          src="/vectori194-xs7e.svg"
                          alt="VectorI194"
                          className="frame9-home-v2-vector16"
                        />
                        <img
                          src
                          alt="VectorI194"
                          className="frame9-home-v2-vector17"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <span className="frame9-home-v2-text061 bodymediumsemibold">
                  <span>Top-up</span>
                </span>
              </div> */}
              {/* <div className="frame9-home-v2-item01">
                <div className="frame9-home-v2-icon3">
                  <div className="frame9-home-v2-cardsend">
                    <div className="frame9-home-v2-cardsend1">
                      <div className="frame9-home-v2-cardsend2">
                        <img
                          src="/vectori194-su6c.svg"
                          alt="VectorI194"
                          className="frame9-home-v2-vector18"
                        />
                        <img
                          src="/vectori194-u1x9.svg"
                          alt="VectorI194"
                          className="frame9-home-v2-vector19"
                        />
                        <img
                          src="/vectori194-023.svg"
                          alt="VectorI194"
                          className="frame9-home-v2-vector20"
                        />
                        <img
                          src="/vectori194-tc46.svg"
                          alt="VectorI194"
                          className="frame9-home-v2-vector21"
                        />
                        <img
                          src="/vectori194-rpqc.svg"
                          alt="VectorI194"
                          className="frame9-home-v2-vector22"
                        />
                        <img
                          src="/vectori194-vs8r.svg"
                          alt="VectorI194"
                          className="frame9-home-v2-vector23"
                        />
                        <img
                          src
                          alt="VectorI194"
                          className="frame9-home-v2-vector24"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <span className="frame9-home-v2-text063 bodymediumsemibold">
                  <span>Send</span>
                </span>
              </div> */}
              {/* <div className="frame9-home-v2-item02">
                <div className="frame9-home-v2-icon4">
                  <div className="frame9-home-v2-cardreceive">
                    <div className="frame9-home-v2-cardreceive1">
                      <div className="frame9-home-v2-cardreceive2">
                        <img
                          src="/vectori194-qik.svg"
                          alt="VectorI194"
                          className="frame9-home-v2-vector25"
                        />
                        <img
                          src="/vectori194-7hem.svg"
                          alt="VectorI194"
                          className="frame9-home-v2-vector26"
                        />
                        <img
                          src="/vectori194-6zq4.svg"
                          alt="VectorI194"
                          className="frame9-home-v2-vector27"
                        />
                        <img
                          src="/vectori194-l6qv.svg"
                          alt="VectorI194"
                          className="frame9-home-v2-vector28"
                        />
                        <img
                          src="/vectori194-1nnk.svg"
                          alt="VectorI194"
                          className="frame9-home-v2-vector29"
                        />
                        <img
                          src="/vectori194-fbv.svg"
                          alt="VectorI194"
                          className="frame9-home-v2-vector30"
                        />
                        <img
                          src
                          alt="VectorI194"
                          className="frame9-home-v2-vector31"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <span className="frame9-home-v2-text065 bodymediumsemibold">
                  <span>Request</span>
                </span>
              </div> */}
              {/* <div className="frame9-home-v2-item03">
                <div className="frame9-home-v2-icon5">
                  <div className="frame9-home-v2-receipt3">
                    <div className="frame9-home-v2-receipt4">
                      <div className="frame9-home-v2-receipt5">
                        <img
                          src="/vectori194-do2.svg"
                          alt="VectorI194"
                          className="frame9-home-v2-vector32"
                        />
                        <img
                          src="/vectori194-30ap.svg"
                          alt="VectorI194"
                          className="frame9-home-v2-vector33"
                        />
                        <img
                          src="/vectori194-yqdh.svg"
                          alt="VectorI194"
                          className="frame9-home-v2-vector34"
                        />
                        <img
                          src
                          alt="VectorI194"
                          className="frame9-home-v2-vector35"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <span className="frame9-home-v2-text067 bodymediumsemibold">
                  <span>History</span>
                </span>
              </div> */}
            </div>
            <div className="frame9-home-v2-indicator">
              <img
                src
                alt="Rectangle5I194"
                className="frame9-home-v2-rectangle5"
              />
              <img
                src
                alt="Rectangle6I194"
                className="frame9-home-v2-rectangle6"
              />
              <img
                src
                alt="Rectangle7I194"
                className="frame9-home-v2-rectangle7"
              />
            </div>
          </div>
          <div className="frame9-home-v2-business-account">
            <img
              src={business}
              alt="backgroundI194"
              className="frame9-home-v2-background1"
            />
            {/* <div className="frame9-home-v2-name2">
              <div className="frame9-home-v2-text069">
                <span className="frame9-home-v2-text070 bodysmallmedium">
                  <span>Sweep to Bank</span>
                </span>
                <span className="frame9-home-v2-text072 HeadingH5">
                  <span>$1,098.00</span>
                </span>
              </div>
              <div className="frame9-home-v2-icon6">
                <div className="frame9-home-v2-dollarcircle">
                  <div className="frame9-home-v2-dollarcircle1">
                    <div className="frame9-home-v2-dollarcircle2">
                      <img
                        src="/vectori194-9axp.svg"
                        alt="VectorI194"
                        className="frame9-home-v2-vector36"
                      />
                      <img
                        src="/vectori194-b45c.svg"
                        alt="VectorI194"
                        className="frame9-home-v2-vector37"
                      />
                      <img
                        src="/vectori194-i4ay.svg"
                        alt="VectorI194"
                        className="frame9-home-v2-vector38"
                      />
                      <img
                        src
                        alt="VectorI194"
                        className="frame9-home-v2-vector39"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="frame9-home-v2-stats2">
              <img src alt="barI194" className="frame9-home-v2-bar10" />
              <img src alt="barI194" className="frame9-home-v2-bar11" />
              <img src alt="barI194" className="frame9-home-v2-bar12" />
              <img src alt="barI194" className="frame9-home-v2-bar13" />
              <img src alt="barI194" className="frame9-home-v2-bar14" />
              <div className="frame9-home-v2-amount2">
                <img
                  src="/unioni194-axqj.svg"
                  alt="UnionI194"
                  className="frame9-home-v2-union1"
                />
                <span className="frame9-home-v2-text074 bodyxsmallsemibold">
                  <span>$5,052</span>
                </span>
              </div>
            </div> */}
          </div>
          Moneyflow Graph
          <div className="frame9-home-v2-moneyflow">
            <span className="frame9-home-v2-text004 HeadingH6">
              <span>Money Flow</span>
            </span>
            <div className="frame9-home-v2-date">
              <span className="frame9-home-v2-text006 bodysmallsemibold">
                <span>Jan 10 - Jan 16</span>
              </span>
              <img
                src={dropdown}
                alt="chevrondown1948"
                className="frame9-home-v2-chevrondown1"
              />
            </div>
            <div className="frame9-home-v2-income">
              <img
                src
                alt="Rectangle11948"
                className="frame9-home-v2-rectangle1"
              />
              <span className="frame9-home-v2-text008 bodysmallsemibold">
                <span>Income</span>
              </span>
            </div>
            <div className="frame9-home-v2-amount">
              <span className="frame9-home-v2-text010 bodysmallregular">
                <span>10k</span>
              </span>
              <span className="frame9-home-v2-text012 bodysmallregular">
                <span>7k</span>
              </span>
              <span className="frame9-home-v2-text014 bodysmallregular">
                <span>5k</span>
              </span>
              <span className="frame9-home-v2-text016 bodysmallregular">
                <span>3k</span>
              </span>
              <span className="frame9-home-v2-text018 bodysmallregular">
                <span>0k</span>
              </span>
            </div>
            <div className="frame9-home-v2-date1">
              <span className="frame9-home-v2-text020 bodysmallregular">
                <span>Jan 10</span>
              </span>
              <span className="frame9-home-v2-text022 bodysmallregular">
                <span>Jan 11</span>
              </span>
              <span className="frame9-home-v2-text024 bodysmallextrabold">
                <span>Jan 12</span>
              </span>
              <span className="frame9-home-v2-text026 bodysmallregular">
                <span>Jan 13</span>
              </span>
              <span className="frame9-home-v2-text028 bodysmallregular">
                <span>Jan 14</span>
              </span>
              <span className="frame9-home-v2-text030 bodysmallregular">
                <span>Jan 15</span>
              </span>
              <span className="frame9-home-v2-text032 bodysmallregular">
                <span>Jan 16</span>
              </span>
            </div>
            <div className="frame9-home-v2-expenses">
              <img
                src
                alt="Rectangle11948"
                className="frame9-home-v2-rectangle11"
              />
              <span className="frame9-home-v2-text034 bodysmallsemibold">
                <span>Expenses</span>
              </span>
            </div>
            <div className="frame9-home-v2-line">
              <img
                src="/line1948-28r.svg"
                alt="line1948"
                className="frame9-home-v2-line01"
              />
              <img
                src="/line1948-1y1p.svg"
                alt="line1948"
                className="frame9-home-v2-line02"
              />
              <img
                src="/line1948-j8eu.svg"
                alt="line1948"
                className="frame9-home-v2-line03"
              />
              <img
                src="/line1948-19h.svg"
                alt="line1948"
                className="frame9-home-v2-line04"
              />
              <img
                src="/line1948-dfi2.svg"
                alt="line1948"
                className="frame9-home-v2-line05"
              />
              <img
                src="/line1948-mae.svg"
                alt="line1948"
                className="frame9-home-v2-line06"
              />
              <img
                src="/line1948-nagh.svg"
                alt="line1948"
                className="frame9-home-v2-line07"
              />
              <img
                src="/line1948-d13s.svg"
                alt="line1948"
                className="frame9-home-v2-line08"
              />
              <img
                src="/line1948-0z.svg"
                alt="line1948"
                className="frame9-home-v2-line09"
              />
              <img
                src="/line1948-yds.svg"
                alt="line1948"
                className="frame9-home-v2-line10"
              />
              <img
                src="/line1948-o3nt.svg"
                alt="line1948"
                className="frame9-home-v2-line11"
              />
              <img
                src="/line1948-5oon.svg"
                alt="line1948"
                className="frame9-home-v2-line12"
              />
              <img
                src="/line1948-4l9g.svg"
                alt="line1948"
                className="frame9-home-v2-line13"
              />
              <img
                src="/line1948-lje9.svg"
                alt="line1948"
                className="frame9-home-v2-line14"
              />
              <img
                src="/line1948-czui.svg"
                alt="line1948"
                className="frame9-home-v2-line15"
              />
              <img
                src="/line1948-fwak.svg"
                alt="line1948"
                className="frame9-home-v2-line16"
              />
              <img
                src="/line1948-erwg.svg"
                alt="line1948"
                className="frame9-home-v2-line17"
              />
              <img
                src="/line1948-swt.svg"
                alt="line1948"
                className="frame9-home-v2-line18"
              />
            </div>
            <div className="frame9-home-v2-chartline">
              <img
                src={blackLine}
                alt="line1948"
                className="frame9-home-v2-line19"
              />
              <img
                src={blueLine}
                alt="line1948"
                className="frame9-home-v2-line20"
              />
              <img src={dot} alt="dot1948" className="frame9-home-v2-dot" />
              <div className="frame9-home-v2-amount1">
                <img
                  src={blackUnion}
                  alt="Union1948"
                  className="frame9-home-v2-union"
                />
                <span className="frame9-home-v2-text036 bodysmallsemibold">
                  <span>$5,052</span>
                </span>
              </div>
            </div>
          </div>
          yet to implement
          {/* <div className="dashboard-graph">
            <div className="dashboard-minigraph">
              <div className="frame9-home-v2-name ">
                <div className="frame9-home-v2-text038">
                  <span className="frame9-home-v2-text039 bodysmallmedium">
                    <span>Total Month Earn</span>
                  </span>
                  <span className="frame9-home-v2-text041 HeadingH5">
                    <span>$2,456.89</span>
                  </span>
                </div> 
                <div className="frame9-home-v2-icon">
                  <div className="frame9-home-v2-receipt">
                    <div className="frame9-home-v2-receipt1">
                      <div className="frame9-home-v2-receipt2">
                        <img
                          src="/vectori194-yrm.svg"
                          alt="VectorI194"
                          className="frame9-home-v2-vector02"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dashboard-minigraph">
              <div className="frame9-home-v2-name">
                <div className="frame9-home-v2-text043">
                  <span className="frame9-home-v2-text044 bodysmallmedium">
                    <span>Total claims</span>
                  </span>
                  <span className="frame9-home-v2-text046 HeadingH5">
                    <span>$280.00</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="dashboard-minigraph">
              <div className="frame9-home-v2-name">
                <div className="frame9-home-v2-text069">
                  <span className="frame9-home-v2-text070 bodysmallmedium">
                    <span>Sweep to Bank</span>
                  </span>
                  <span className="frame9-home-v2-text072 HeadingH5">
                    <span>$1,098.00</span>
                  </span>
                </div>
              </div>
              {/* <div className="frame9-home-v2-stats2">
                <img src alt="barI194" className="frame9-home-v2-bar10" />
                <img src alt="barI194" className="frame9-home-v2-bar11" />
                <img src alt="barI194" className="frame9-home-v2-bar12" />
                <img src alt="barI194" className="frame9-home-v2-bar13" />
                <img src alt="barI194" className="frame9-home-v2-bar14" />
                <div className="frame9-home-v2-amount2">
                  <img
                    src="/unioni194-axqj.svg"
                    alt="UnionI194"
                    className="frame9-home-v2-union1"
                  />
                  <span className="frame9-home-v2-text074 bodyxsmallsemibold">
                    <span>$5,052</span>
                  </span>
                </div>
              </div>
            </div>
          </div>*/}
          <Wallet />
          {/* <Bank /> */}
          <FinalCheckOutElement />
          <div className="frame9-home-v2-recenttransactions">
            <div className="frame9-home-v2-view">
              <span className="frame9-home-v2-text095 HeadingH6">
                <span>Recent Transactions</span>
              </span>
            </div>
            <div className="table-container">
              <Table columns={columns} data={transactions} />
            </div>
            <div>
              <Link to="/invoices" className="navbar-button">
                <span className="frame9-home-v2-text093 bodysmallsemibold">
                  <span>View all</span>
                  <img
                    src={dropdownside}
                    alt="chevrondown1948"
                    className="frame9-home-v2-chevrondown3"
                  />
                </span>
              </Link>
            </div>
          </div>
        </div>
        <Navbar isOpen={isSidebarOpen} />
      </div>
    </div>
  );
};

export default Dashboard;
