import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/Logo.png';
import home from '../../assets/Home.png';
import invoice from '../../assets/Invoice.png';
import products from '../../assets/products.png';
import activity from '../../assets/activity.png';
import help from '../../assets/help.png';
import setting from '../../assets/setting.png';
import dropdown from '../../assets/dropdown.png';
import message from '../../assets/message.png';
import './Dashboard.css'; // Import your CSS file

const navHeader = [
  { to: '/dashboard', imgSrc: home, text: 'My Wallet' },
  { to: '/invoices', imgSrc: invoice, text: 'Invoices' },
  { to: '/products', imgSrc: products, text: 'Products' },
  { to: '/services', imgSrc: products, text: 'Services' },
  { to: '/messages', imgSrc: message, text: 'Messages'},
];
const navFooter = [
  { to: '/help', imgSrc: help, text: 'Get Help' },
  { to: '/settings', imgSrc: setting, text: 'Settings' }
];

const Sidebar = ({ isOpen }) => {
  return (
    <div className={`frame9-home-v2-sidebar ${isOpen ? 'open' : ''}`}>
    <div className="navbar-header">
      {navHeader.map((item, index) => (
        <Link to={item.to} className="navbar-button" key={index}>
          
          <div className="frame9-home-v2-home2">
        <div className="frame9-home-v2-vuesaxoutlinehome2">
          <div className="frame9-home-v2-home21">
            <img
              src={item.imgSrc}
              alt="VectorI194"
              className="frame9-home-v2-vector42"
            />
          </div>
        </div>
      </div>
      <span className="frame9-home-v2-text076 bodymediumextrabold">
        <span>{item.text}</span>
      </span>
        </Link>
      ))}
    </div>
      <div className="navbar-footer">
        {navFooter.map((item, index) => (
          <Link to={item.to} className="navbar-button" key={index}>
            <div className="frame9-home-v2-home2">
          <div className="frame9-home-v2-vuesaxoutlinehome2">
            <div className="frame9-home-v2-home21">
              <img
                src={item.imgSrc}
                alt="VectorI194"
                className="frame9-home-v2-vector42"
              />
            </div>
          </div>
        </div>
        <span className="frame9-home-v2-text076 bodymediumextrabold">
          <span>{item.text}</span>
        </span>
          </Link>
        ))}
      </div>
      <div className="frame9-home-v2-logo">
        <div className="frame9-home-v2-frame48">
          <div className="frame9-home-v2-logo1">
            <div className="frame9-home-v2-logo2">
              <div className="frame9-home-v2-ontologyont">
                <img src={logo} alt="SkenderPay" className="frame9-home-v2-skender-pay1" />
              </div>
            </div>
          </div>
          <span className="frame9-home-v2-text091 HeadingH5">
            <span>Skenderpay</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
