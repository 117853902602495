import logo from "../../assets/Logo.png";
import middleImg from "../../assets/MiddleImg.png";
import React, { useState, useEffect } from "react";
import module from "./style.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginAction } from "../../store/login-slice";
import { Eye, EyeOff } from "lucide-react";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [shake, setShake] = useState(false);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // Mount animation
  useEffect(() => {
    setMounted(true);
  }, []);

  // Handle touch events for swipe
  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;

    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;

    if (isLeftSwipe) {
      navigate("/signup1");
    } else if (isRightSwipe) {
      // Optional: Add right swipe action
    }

    setTouchStart(null);
    setTouchEnd(null);
  };

  const validateEmail = (input) => {
    const validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return input.match(validRegex);
  };

  const validatePassword = (input) => {
    return (
      input.length >= 8 &&
      /[a-z]/.test(input) &&
      /[A-Z]/.test(input) &&
      /[0-9]/.test(input)
    );
  };

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    emailTouched: false,
    passwordTouched: false,
  });

  const emailIsValid = validateEmail(formData.email);
  const passwordIsValid = validatePassword(formData.password);
  const formIsValid = emailIsValid && passwordIsValid;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleBlur = (field) => {
    setFormData((prev) => ({
      ...prev,
      [`${field}Touched`]: true,
    }));
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    if (!formIsValid) {
      setShake(true);
      setTimeout(() => setShake(false), 500);
      return;
    }

    setIsLoading(true);
    try {
      const resp = await validatedUser({
        email: formData.email,
        password: formData.password,
      });

      if (resp?.message === "success") {
        setError(false);
        dispatch(
          loginAction.userLogin({
            userId: resp.userId,
            firstName: resp.firstName,
          })
        );
        // Add fade-out animation before navigation
        setMounted(false);
        setTimeout(() => {
          navigate("/dashboard");
        }, 300);
      } else {
        setError(true);
        setShake(true);
        setTimeout(() => setShake(false), 500);
      }
    } catch (err) {
      setError(true);
      setShake(true);
      setTimeout(() => setShake(false), 500);
    } finally {
      setIsLoading(false);
    }
  };

  async function validatedUser(data) {
    const resp = await fetch("https://skenderpay.xyz:8443/check", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const parsedResp = await resp.json();
    return {
      message: parsedResp.message,
      userId: parsedResp.user_id,
      firstName: parsedResp.FirstName,
    };
  }

  // Dynamic classes for animations
  const containerClasses = `${module.innerContainer} ${
    mounted ? module.fadeIn : module.fadeOut
  } ${shake ? module.shake : ""}`;

  return (
    <div
      className={module.parentContainer}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className={module.container}>
        <div className={containerClasses}>
          {/* First parent section (left side) */}
          <div
            className={`${module.firstParent} ${
              mounted ? module.slideInLeft : ""
            }`}
          >
            {/* Existing first parent content */}
            <div className={module.innerFirstParent}>
              <div
                className={`${module.subInnerParent} ${
                  mounted ? module.fadeInDown : ""
                }`}
              >
                <div className={module.logo}>
                  <img src={logo} alt="SkenderPayLogo" />
                </div>
                <div className={module.textSpan}>Skenderpay</div>
              </div>
              <div className={module.firstImage}></div>
            </div>
            <div
              className={`${module.middleImage} ${
                mounted ? module.fadeInUp : ""
              }`}
            >
              <img src={middleImg} alt="Middle showcase" />
            </div>
            <div
              className={`${module.textParent} ${
                mounted ? module.fadeInUp : ""
              }`}
            >
              <span className={module.text1}>
                Fast, Secure, User-Friendly Digital Payments!
              </span>
              <span className={module.text2}>
                Exciting developments are on the horizon for the digital payment
                landscape with the imminent arrival of a cutting-edge platform
                at the intersection of AI and blockchain payments technology.
                <br />
                <span className={module.dollarText}></span>
                Our innovative solution promises to revolutionize transactions
                offering unparalleled security, efficiency, and transparency.
                <br />
                Stay tuned for the unveiling of SkenderPay platform the
                game-changing digital payment platform, set to redefine the way
                we engage in financial transactions.
              </span>
            </div>
            <div className={module.secondImage}></div>
          </div>

          {/* Second parent section (right side) */}
          <div
            className={`${module.secondParent} ${
              mounted ? module.slideInRight : ""
            }`}
          >
            <div className={module.formParent}>
              <div
                className={`${module.formHeading} ${
                  mounted ? module.fadeInDown : ""
                }`}
              >
                <h2>Login</h2>
              </div>
              <form onSubmit={onSubmitHandler}>
                {error && (
                  <div
                    className={`${module.invalidUser} ${module.errorText} ${module.fadeIn}`}
                  >
                    <p>Either your username or password is incorrect</p>
                  </div>
                )}

                <div
                  className={`${module.inputGroup} ${
                    mounted ? module.fadeInUp : ""
                  }`}
                >
                  <label className={module.labelUserName} htmlFor="email">
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className={`${module.inputUserName} ${
                      formData.emailTouched && !emailIsValid
                        ? module.invalid
                        : ""
                    }`}
                    onChange={handleInputChange}
                    onBlur={() => handleBlur("email")}
                    value={formData.email}
                    placeholder="Enter your email"
                  />
                  {formData.emailTouched && !emailIsValid && (
                    <p className={`${module.fieldError} ${module.fadeIn}`}>
                      Please enter a valid email address
                    </p>
                  )}
                </div>

                <div
                  className={`${module.inputGroup} ${
                    mounted ? module.fadeInUp : ""
                  }`}
                >
                  <label className={module.labelPassword} htmlFor="password">
                    Password
                  </label>
                  <div className={module.passwordWrapper}>
                    <input
                      type={showPassword ? "text" : "password"}
                      id="password"
                      name="password"
                      className={`${module.inputPassword} ${
                        formData.passwordTouched && !passwordIsValid
                          ? module.invalid
                          : ""
                      }`}
                      onChange={handleInputChange}
                      onBlur={() => handleBlur("password")}
                      value={formData.password}
                      placeholder="Enter your password"
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className={module.passwordToggle}
                    >
                      {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                    </button>
                  </div>
                  {formData.passwordTouched && !passwordIsValid && (
                    <p className={`${module.fieldError} ${module.fadeIn}`}>
                      Password must be at least 8 characters and include
                      uppercase, lowercase, and numbers
                    </p>
                  )}
                </div>

                <button
                  type="submit"
                  className={`${module.loginButton} ${
                    !formIsValid || isLoading ? module.buttonDisabled : ""
                  } ${mounted ? module.fadeInUp : ""}`}
                  disabled={!formIsValid || isLoading}
                >
                  {isLoading ? "Signing in..." : "Login"}
                </button>

                <div
                  className={`${module.buttonContainer} ${
                    mounted ? module.fadeInUp : ""
                  }`}
                >
                  <button
                    type="button"
                    className={module.registerButton}
                    onClick={() => navigate("/signup1")}
                  >
                    New User?
                  </button>
                  <button
                    type="button"
                    className={module.forgotButton}
                    onClick={() => navigate("/passwordreset")}
                  >
                    Forgot Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
