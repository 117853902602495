import React from 'react';
import Navbar from './Navbar';
import NavbarTop from './NavbarTop';
import { useState } from 'react';

const Help = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="frame9-home-v2-container">
      <div className="frame9-home-v2-frame9-home-v2">
        <div className="frame9-home-v2-content">
          <NavbarTop moduleName="Help" onNavbarClick={toggleSidebar} />
          <div className="help-content">
            <h2>Contact Support</h2>
            <p>If you have any questions, please contact us at:</p>
            <p><strong>xyz@email</strong></p>
          </div>
        </div>
        <Navbar isOpen={isSidebarOpen} />
      </div>
    </div>
  );
};

export default Help;
