import React, { useState } from 'react';
import './Dashboard.css'
import bitcoin from '../../assets/bitcoin.png'
import profile2 from '../../assets/profile.png'
import paypal from '../../assets/paypal.png'
import Navbar from './Navbar'
import NavbarTop from './NavbarTop'
import { useTable, useSortBy } from 'react-table';
const transactions = [
  {
    transactionType: 'Bitcoin',
    recipient: '',
    date: 'Jan 16, 2022',
    amount: '-$835.00',
    status: 'Success'
  },
  {
    transactionType: 'Bitcoin',
    recipient: '',
    date: 'Jan 16, 2022',
    amount: '-$835.00',
    status: 'Success'
  },
  {
    transactionType: 'Sent to Antonio',
    recipient: '',
    date: 'Jan 14, 2022',
    amount: '-$150.00',
    status: 'Pending'
  },
  {
    transactionType: 'Paypal',
    recipient: '',
    date: 'Jan 13, 2022',
    amount: '+$200.00',
    status: 'Success'
  }
];

const Table = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns,
      data
    },
    useSortBy
  );

  return (
    <table {...getTableProps()} className="product-list">
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()} className="product-item">
            {headerGroup.headers.map(column => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className={
                  column.isSorted
                    ? column.isSortedDesc
                      ? 'sort-desc'
                      : 'sort-asc'
                    : ''
                }
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className="product-item">
              {row.cells.map(cell => {
                // Add specific classes based on status
                let statusClass = '';
                if (cell.column.id === 'status') {
                  statusClass = cell.value === 'Pending' ? 'product-pending' : 'product-success';
                }
                return (
                  <td {...cell.getCellProps()} className={`product-name ${statusClass}`}>
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
const Invoices = (props) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  
  const columns = React.useMemo(
    () => [
      {
        Header: 'Transactions Name',
        accessor: 'transactionType'
      },
      {
        Header: 'Recipient',
        accessor: 'recipient'
      },
      {
        Header: 'Date',
        accessor: 'date'
      },
      {
        Header: 'Price',
        accessor: 'amount'
      },
      {
        Header: 'Status',
        accessor: 'status'
      }
    ],
    []
  );
  return (
    <div className="frame9-home-v2-container">
      <div className="frame9-home-v2-frame9-home-v2">
        <div className="frame9-home-v2-content">
          <NavbarTop  moduleName="Invoices" onNavbarClick={toggleSidebar} />
          <div className="product-invoices">
          <h2>Recent Invoices</h2>
            <Table columns={columns} data={transactions} />
          </div>
        </div>
        <Navbar isOpen={isSidebarOpen}/>
      </div>
    </div>
  )
}

export default Invoices
