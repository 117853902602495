import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import "./checkout.css";

function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const [amount, setAmount] = useState();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    // Handle payment submission logic here
  };

  return (
    <form onSubmit={handleSubmit} className="form-container">
      <h2 className="headings">Checkout</h2>
      <div className="parent-div">
        <div>
          <label>Amount</label>
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className="input-field"
            placeholder="Enter amount"
          />
        </div>
        <label>Card Details</label>
        <CardElement className="card-element" />
        <button
          type="submit"
          disabled={!stripe}
          className="submit-button"
        >
          Submit Payment
        </button>
      </div>
    </form>
  );
}

export default CheckoutForm;
