import React, { useState, useEffect, useRef } from 'react';
import dropdown from '../../assets/dropdown.png';
import search from '../../assets/search.png';
import bell from '../../assets/bell.png';
import reddot from '../../assets/redDot.png';
import { useSelector, useDispatch } from 'react-redux';
import { loginAction } from '../../store/login-slice'; 

const Navbar = ({ moduleName, onNavbarClick }) =>  {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const dropdownRef = useRef(null);
  const notificationRef = useRef(null);
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const username = useSelector((state) => state.login.firstName);

  const notifications = [
    {
      notification_id: 1,
      user_id: 3,
      notification_timestamp: '2024-09-08 12:00:00',
      notification_content: 'Your recent transaction has been successfully processed.',
    },
    {
      notification_id: 2,
      user_id: 3,
      notification_timestamp: '2024-09-08 14:30:00',
      notification_content: 'A new login was detected from an unknown device. Please verify if this was you.',
    },
    {
      notification_id: 3,
      user_id: 3,
      notification_timestamp: '2024-09-08 15:45:00',
      notification_content: 'Your password has been changed successfully.',
    },
    {
      notification_id: 4,
      user_id: 3,
      notification_timestamp: '2024-09-08 17:00:00',
      notification_content: 'Reminder: Your subscription is due for renewal in 3 days.',
    },
    {
      notification_id: 5,
      user_id: 3,
      notification_timestamp: '2024-09-08 18:15:00',
      notification_content: 'You have a new message from customer support.',
    },
  ];

  const userNotifications = notifications.filter(notification => notification.user_id === 3);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  }; 

  const toggleNotificationPanel = () => {
    setIsNotificationOpen(!isNotificationOpen);
  };

  const handleLogout = () => {
    dispatch(loginAction.userLogout());
  };

  // Close dropdown/notification panel if clicked outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setIsNotificationOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, notificationRef]);

  return (
    <div className="frame9-home-v2-navbar-right"> 
      <span className="navbar-heading HeadingH4" onClick={onNavbarClick}>
        <h3>{moduleName}</h3>
      </span>
      <div className="frame9-home-v2-notifications" ref={notificationRef}>
        <div className="frame9-home-v2-bell" onClick={toggleNotificationPanel}>
          <div className="frame9-home-v2-group">
            <img src={bell} alt="VectorI194" className="frame9-home-v2-vector" />
          </div>
        </div>
        <img src={reddot} alt="Ellipse741948" className="frame9-home-v2-ellipse74" />
      </div>
      <div className="frame9-home-v2-search">
        <div className="frame9-home-v2-search1">
          <div className="frame9-home-v2-search2">
            <img src={search} alt="Ellipse739I194" className="frame9-home-v2-ellipse739" />
          </div>
        </div>
      </div>
      <div className="frame9-home-v2-user" ref={dropdownRef}>
        <div className="frame9-home-v2-frame5">
          <span className="frame9-home-v2-text002 bodylargeextrabold">
            <span>{username}</span>
          </span>
        </div>
        <img
          src={dropdown}
          alt="chevrondown1948"
          className="frame9-home-v2-chevrondown"
          onClick={toggleDropdown}
        />
        {isDropdownOpen && (
          <div className="dropdown-content">
            <h4>{username}</h4>
            <h6>Business Account</h6>
            <button>Your Details</button>
            <button onClick={handleLogout}>Logout</button>
            <button>Account Settings</button>
          </div>
        )}
      </div>
      {isNotificationOpen && (
        <div className="notification-panel" ref={notificationRef}>
          <h3>Notifications</h3>
          {userNotifications.map((notification) => (
            <div key={notification.notification_id} className="notification-item">
              <p>{notification.notification_timestamp}</p>
              <p>{notification.notification_content}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Navbar;
